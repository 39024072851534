.logo {
  height: 2rem;
  width: auto;
}

.brand-name {
  margin-left: 1rem;
  color: #aa55ff;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
}
